import { useState, useRef } from "react";
import ReactPlayer from "react-player";
import PuzzlesVideo from "../../../assets/videos/puzzles.mp4";
import LearningSoundsVideo from "../../../assets/videos/learning_sounds.mp4";
import LearnWordsVideo from "../../../assets/videos/learn_words.mp4.mov";
import TongueTwisterVideo from "../../../assets/videos/tongue_twisters.mp4";
import RepeatLettersVideo from "../../../assets/videos/repeat_letters.mp4";
import ArticulationExerciesVideo from "../../../assets/videos/training_exercises.mp4";
import LearnToCountVideo from "../../../assets/videos/learn_to_count.mp4";
import YesBtn from "../../../assets/images/yes_btn.svg";
import NoBtn from "../../../assets/images/no_btn.svg";
import PuzzlesLogo from "../../../assets/images/puzzles_logo.svg";
import InactivePuzzlesLogo from "../../../assets/images/puzzles_logo_grey.svg";
import ArticulationExerciesLogo from "../../../assets/images/training_exercises.svg";
import InactiveArticulationExcercisesLogo from "../../../assets/images/training_exercises_grey.svg";
import LearningSoundsLogo from "../../../assets/images/learning_sounds.svg";
import InactiveLeraningSoundsLogo from "../../../assets/images/learning_sounds_grey.svg";
import LearningWordsLogo from "../../../assets/images/learning_words.svg";
import InactiveLearningWordsLogo from "../../../assets/images/learning_words_grey.svg";
import TongueTwistersLogo from "../../../assets/images/tongue_twisters.svg";
import InactiveTongueTwistersLogo from "../../../assets/images/tongue_twisters_grey.svg";
import RepeatWordsLogo from "../../../assets/images/repeat_words.svg";
import InactiveRepeatWordsLogo from "../../../assets/images/repeat_words_grey.svg";
import LearnToCountLogo from "../../../assets/images/learn_the_count_logo.svg";
import InactiveLearnToCount from "../../../assets/images/learn_the_count_logo_grey.svg";
import BackButton from "../../../assets/images/back.svg";
import RepeatIcon from "../../../assets/images/repeat_icon.svg";
import { CircularProgress } from "@mui/material"; 

const stepsData = [
  {
    id: "puzzles",
    video: PuzzlesVideo,
    logo: PuzzlesLogo,
    title: "Puzzles",
    inactiveLogo: InactivePuzzlesLogo,
  },
  {
    id: "articulation_exercises",
    video: ArticulationExerciesVideo,
    logo: ArticulationExerciesLogo,
    title: "Articulation Exercises",
    inactiveLogo: InactiveArticulationExcercisesLogo,
  },
  {
    id: "learning_sound",
    video: LearningSoundsVideo,
    logo: LearningSoundsLogo,
    title: "Learn The Sound",
    inactiveLogo: InactiveLeraningSoundsLogo,
  },
  {
    id: "learning_word",
    video: LearnWordsVideo,
    logo: LearningWordsLogo,
    title: "Learn Words",
    inactiveLogo: InactiveLearningWordsLogo,
  },
  {
    id: "learn_to_count",
    video: LearnToCountVideo,
    logo: LearnToCountLogo,
    inactiveLogo: InactiveLearnToCount,
    title: "Learn to Count",
  },
  {
    id: "tongue_twisters",
    video: TongueTwisterVideo,
    logo: TongueTwistersLogo,
    title: "Tongue Twisters",
    inactiveLogo: InactiveTongueTwistersLogo,
  },
  {
    id: "repeat_words",
    video: RepeatLettersVideo,
    logo: RepeatWordsLogo,
    title: "Repeat Letters",
    inactiveLogo: InactiveRepeatWordsLogo,
  },
];

const Questions = ({ age, callback }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showRepeat, setShowRepeat] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [controlsVisible, setControlsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const playerRef = useRef(null);

  const handleAnswer = (answer) => {
    setAnswers({
      ...answers,
      [currentStep]: answer,
    });

    const updatedSelectedCategories =
      answer === "yes"
        ? [...selectedCategories, stepsData[currentStep].id]
        : selectedCategories;

    if (currentStep === stepsData.length - 1) {
      callback({
        ...answers,
        step: 3,
        selectedCategories: updatedSelectedCategories,
      });
    } else {
      setCurrentStep(currentStep + 1);
      setPlaying(true);
      setShowRepeat(false);
    }
    setLoading(true)
    console.log(updatedSelectedCategories);
    setSelectedCategories(updatedSelectedCategories);
  };

  const handleBack = () => {
    setCurrentStep(0);
    setAnswers({});
    setSelectedCategories([]);
    setPlaying(true);
    callback({ step: 1 });
  };

  const handleVideoEnded = () => {
    setShowRepeat(true);
    setPlaying(false);
  };

  const handleRepeat = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(0);
      setPlaying(true);
      setShowRepeat(false);
      setControlsVisible(false);
    }
  };

  const handlePlayerClick = () => {
    setControlsVisible(!controlsVisible);
  };

  const handleVideoReady = () => {
    console.log("Video is ready to play");
    setLoading(false);
  };

  const handleVideoStart = () => {
    console.log("Video has started playing");
    setLoading(false);
  };

  const handleBuffer = () => {
    console.log("Video is buffering");
    setLoading(true);
  };

  const handleBufferEnd = () => {
    console.log("Buffering ended");
    setLoading(false);
  };

  const currentData = stepsData[currentStep];

  return (
    <div
      className="questions-container"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        margin: "0 auto",
        position: "relative",
        boxSizing: "border-box",
      }}
    >
      <div
        className="video-container"
        style={{
          marginTop: "40px",
          border: "7px solid #006B99",
          borderRadius: "15px",
          overflow: "hidden",
          position: "relative",
          width: "100%",
          paddingTop: "56.25%",
          boxSizing: "border-box",
        }}
        onClick={handlePlayerClick}
      >
        <img
          src={BackButton}
          alt="Back"
          onClick={handleBack}
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            width: "60px",
            height: "60px",
            cursor: "pointer",
            zIndex: 5,
          }}
        />

        {loading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 6,
            }}
          >
            <CircularProgress
              style={{ color: "#006B99", width: "100px", height: "100px" }}
              thickness={4}
            />
          </div>
        )}
        <ReactPlayer
          ref={playerRef}
          url={currentData.video}
          playing={playing}
          controls={controlsVisible}
          width="100%"
          height="auto"
          light={false}
          playsinline
          onEnded={handleVideoEnded}
          onReady={handleVideoReady}
          onStart={handleVideoStart}
          onBuffer={handleBuffer}
          onBufferEnd={handleBufferEnd}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        {showRepeat && (
          <div
            onClick={handleRepeat}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              zIndex: 4,
            }}
          >
            <img
              src={RepeatIcon}
              alt="Repeat"
              style={{
                width: "70px",
                height: "70px",
                filter: "brightness(1.5)",
              }}
            />
          </div>
        )}
      </div>
      <div
        className="title-container"
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: "10px 20px",
          marginTop: "-20px",
          marginBottom: "5px",
          zIndex: 1,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          position: "relative",
          left: "50%",
          transform: "translateX(-50%)",
          maxWidth: "70%",
        }}
      >
        <span
          style={{ fontSize: "18px", fontWeight: "bold", color: "#006B99" }}
        >
          {currentData.title}
        </span>
      </div>
      <div
        className="center-content"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "100px",
        }}
      >
        <div
          className="logo-container"
          style={{
            marginBottom: "10px",
          }}
        >
          <img
            src={currentData.logo}
            alt="step_logo"
            style={{
              width: "130px",
              height: "130px",
            }}
          />
        </div>
        <div
          className="question"
          style={{
            color: "#006B99",
            fontSize: "22px",
            textAlign: "center",
            marginBottom: "0px",
          }}
        >
          Add to learning path?
        </div>
        <div
          className="answer-buttons"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => handleAnswer("no")}
          >
            <img
              src={NoBtn}
              alt="No"
              style={{ width: "100px", height: "100px" }}
            />
            <span
              style={{
                position: "absolute",
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              No
            </span>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => handleAnswer("yes")}
          >
            <img
              src={YesBtn}
              alt="Yes"
              style={{ width: "100px", height: "100px" }}
            />
            <span
              style={{
                position: "absolute",
                color: "#FFFFFF",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Yes
            </span>
          </div>
        </div>
      </div>
      <div
        className="stepper-container"
        style={{
          position: "fixed",
          bottom: "10px",
          left: "0",
          right: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 2,
        }}
      >
        {stepsData.map((step, index) => (
          <div key={step.id} style={{ display: "flex", alignItems: "center" }}>
            {answers[index] ? (
              <img
                src={answers[index] === "yes" ? step.logo : step.inactiveLogo}
                alt={`${step.id} icon`}
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  zIndex: 2,
                }}
              />
            ) : (
              <div
                style={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  backgroundColor: "#006B99",
                  border: "1px solid #00AFE8",
                  zIndex: 2,
                }}
              ></div>
            )}
            {index < stepsData.length - 1 && (
              <div
                style={{
                  width: "15px",
                  height: "3px",
                  backgroundColor: "#fff",
                  marginLeft: "-7px",
                  border: "1px solid #00AFE8",
                  zIndex: 1,
                }}
              ></div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
